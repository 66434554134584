import React, { Component } from 'react';
import axios from 'axios';

export default class Contact extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			email: '',
			message: '',
			submit: '',
			mailSent: false,
			error: null
		};

		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	handleFormSubmit = e => {
		e.preventDefault();
		let parcel = {
			name: this.state.name,
			email: this.state.email,
			message: this.state.message,
			submit: this.state.submit
		};
		axios.post('./send-email.php', parcel).then(res => {
			if (res.data === 'fields') {
				this.setState({ error: 'Please fill in all fields...' });
			} else if (res.data === 'email') {
				this.setState({
					error: 'The email address that you provided is invalid.'
				});
			} else if (res.data === 'server') {
				this.setState({
					error: 'There was an issue with the server. Please try again later!'
				});
			} else if (res.data === null) {
				this.setState({
					error:
						'Weird issues going on! Send an email to Cal below and let him know about this message too!'
				});
			} else {
				this.setState({
					mailSent: true,
					error: 'Your email has been sent!'
				});
			}
		});
	};
	render() {
		return (
			<div id="contact">
				<h1 className="colTit">Contact</h1>
				<p id="form-message">{this.state.error}</p>

				<form method="POST" id="contactForm">
					<input
						type="text"
						name="name"
						className="form-input"
						id="formName"
						placeholder="Name"
						value={this.state.name}
						onChange={e => this.setState({ name: e.target.value })}
					/>
					<input
						type="email"
						name="email"
						className="form-input"
						id="formEmail"
						placeholder="Email"
						value={this.state.email}
						onChange={e => this.setState({ email: e.target.value })}
					/>
					<textarea
						name="message"
						id="formMessage"
						cols="30"
						rows="5"
						placeholder="Message"
						value={this.state.message}
						onChange={e => this.setState({ message: e.target.value })}
					/>
					<button
						id="formSubmit"
						type="submit"
						name="submit"
						className="form-input submit"
						onClick={this.handleFormSubmit}
					>
						Send Email
					</button>
				</form>
			</div>
		);
	}
}
