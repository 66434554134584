import React, { Component } from 'react'
import '../sass/Preloader.scss'

window.addEventListener('load',()=>{
    const preloader = document.querySelector("#preloader");
    preloader.classList.add('preload-finished');
});

export default class Preloader extends Component {
  render() {
    return (
      <div id="preloader">
        <div id="preloaderContent">
            <div id="balls">
                <div id="ball1"></div>
                <div id="ball2"></div>
                <div id="ball3"></div>
            </div>
            <h3 id="loaderText">Loading...</h3>
        </div>
      </div>
    )
  }
}
