import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

function RouteContainer({ routes, location }) {
	return (
		<>
			<TransitionGroup>
				<CSSTransition key={location.key} timeout={{ enter: 300, exit: 300 }} classNames={'fade'}>
					<Switch location={location}>
						{routes.map(({ path, Component }) => (
							<Route key={path} exact path={path} component={Component} />
						))}
					</Switch>
				</CSSTransition>
			</TransitionGroup>
		</>
	);
}

export default withRouter(RouteContainer);
