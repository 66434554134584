import React from 'react';
import { NavLink } from 'react-router-dom';

export default function NavBar(props) {
	return (
		<ul id="navbar">
			{props.routes.map(route => (
				<li key={route.path} className="nav-item">
					<NavLink activeClassName="active" to={route.path} exact>
						{route.name}
					</NavLink>
				</li>
			))}
		</ul>
	);
}
