import React, { Component } from 'react';
import axios from 'axios';
import Calendar from 'react-calendar';

export default class Availability extends Component {
	constructor(props) {
		super(props);
		this.state = {
			eventDates: [],
			possDates: [],
			currentDate: new Date(),
			lastMonth: ('0' + new Date().getMonth()).slice(-2),
			currentMonth: ('0' + (new Date().getMonth() + 1)).slice(-2),
			nextMonth: ('0' + (new Date().getMonth() + 2)).slice(-2),
			currentYear: new Date().getFullYear(),
			nextYear: new Date().getFullYear() + 1,
			numDays: ''
		};
		this.onChange = this.onChange.bind(this);
	}

	componentWillMount() {
		if (this.state.currentMonth === 8 || 3 || 5 || 10) {
			this.setState({ numDays: 30 });
		} else if (
			this.state.currentMonth === 1 &&
			this.state.currentYear % 4 === 0
		) {
			this.setState({ numDays: 29 });
		} else if (this.state.currentMonth === 1) {
			this.setState({ numDays: 28 });
		} else {
			this.setState({ numDays: 31 });
		}
	}
	makeRequest() {
		let baseURLPoss, baseURLWork;
		if (this.state.currentMonth === '12') {
			baseURLPoss =
				'https://clients6.google.com/calendar/v3/calendars/78291vkv1t40r9q47bao7un30k@group.calendar.google.com/events?calendarId=78291vkv1t40r9q47bao7un30k@group.calendar.google.com&singleEvents=true&timeZone=Europe%2FAmsterdam&maxResults=250&sanitizeHtml=false&timeMin=' +
				this.state.currentYear +
				'-' +
				this.state.lastMonth +
				'-15T00%3A00%3A00%2B02%3A00&timeMax=' +
				this.state.nextYear +
				'-' +
				this.state.nextMonth +
				'-15T23%3A59%3A59%2B02%3A00&key=AIzaSyBNlYH01_9Hc5S1J9vuFmu2nUqBZJNAXxs';
			baseURLWork	=
				'https://clients6.google.com/calendar/v3/calendars/b2f8g8daabnmpqo43v04s6fl3g@group.calendar.google.com/events?calendarId=b2f8g8daabnmpqo43v04s6fl3g%40group.calendar.google.com&singleEvents=true&timeZone=Europe%2FAmsterdam&maxAttendees=1&maxResults=250&sanitizeHtml=false&timeMin=' +
				this.state.currentYear +
				'-' +
				this.state.lastMonth +
				'-15T00%3A00%3A00%2B02%3A00&timeMax=' +
				this.state.nextYear +
				'-' +
				this.state.nextMonth +
				'-15T23%3A59%3A59%2B02%3A00&key=AIzaSyBNlYH01_9Hc5S1J9vuFmu2nUqBZJNAXxs';
			} else if (this.state.currentMonth === '01') {
				baseURLPoss =
				'https://clients6.google.com/calendar/v3/calendars/78291vkv1t40r9q47bao7un30k@group.calendar.google.com/events?calendarId=78291vkv1t40r9q47bao7un30k@group.calendar.google.com&singleEvents=true&timeZone=Europe%2FAmsterdam&maxResults=250&sanitizeHtml=false&timeMin=' +
				(this.state.currentYear-1) +
				'-' +
				'12' +
				'-15T00%3A00%3A00%2B02%3A00&timeMax=' +
				this.state.currentYear +
				'-' +
				this.state.nextMonth +
				'-15T23%3A59%3A59%2B02%3A00&key=AIzaSyBNlYH01_9Hc5S1J9vuFmu2nUqBZJNAXxs';
				baseURLWork	=
					'https://clients6.google.com/calendar/v3/calendars/b2f8g8daabnmpqo43v04s6fl3g@group.calendar.google.com/events?calendarId=b2f8g8daabnmpqo43v04s6fl3g%40group.calendar.google.com&singleEvents=true&timeZone=Europe%2FAmsterdam&maxAttendees=1&maxResults=250&sanitizeHtml=false&timeMin=' +
					(this.state.currentYear-1) +
					'-' +
					'12' +
					'-15T00%3A00%3A00%2B02%3A00&timeMax=' +
					this.state.currentYear +
					'-' +
					this.state.nextMonth +
					'-15T23%3A59%3A59%2B02%3A00&key=AIzaSyBNlYH01_9Hc5S1J9vuFmu2nUqBZJNAXxs';
				} else {
					baseURLPoss =
					'https://clients6.google.com/calendar/v3/calendars/78291vkv1t40r9q47bao7un30k@group.calendar.google.com/events?calendarId=78291vkv1t40r9q47bao7un30k@group.calendar.google.com&singleEvents=true&timeZone=Europe%2FAmsterdam&maxResults=250&sanitizeHtml=false&timeMin=' +
					this.state.currentYear +
					'-' +
					this.state.lastMonth +
					'-15T00%3A00%3A00%2B02%3A00&timeMax=' +
					this.state.currentYear +
					'-' +
					this.state.nextMonth +
					'-15T23%3A59%3A59%2B02%3A00&key=AIzaSyBNlYH01_9Hc5S1J9vuFmu2nUqBZJNAXxs';
					baseURLWork	=
						'https://clients6.google.com/calendar/v3/calendars/b2f8g8daabnmpqo43v04s6fl3g@group.calendar.google.com/events?calendarId=b2f8g8daabnmpqo43v04s6fl3g%40group.calendar.google.com&singleEvents=true&timeZone=Europe%2FAmsterdam&maxAttendees=1&maxResults=250&sanitizeHtml=false&timeMin=' +
						this.state.currentYear +
						'-' +
						this.state.lastMonth +
						'-15T00%3A00%3A00%2B02%3A00&timeMax=' +
						this.state.currentYear +
						'-' +
						this.state.nextMonth +
						'-15T23%3A59%3A59%2B02%3A00&key=AIzaSyBNlYH01_9Hc5S1J9vuFmu2nUqBZJNAXxs';
		}

		axios.all([axios.get(baseURLWork), axios.get(baseURLPoss)]).then(
			axios.spread(async (workRes, possRes) => {
				let events = await workRes.data;
				let eventArr = events.items;

				let possEvents = await possRes.data;
				let possEventArr = possEvents.items;

				let datesArr = [];
				let middleDates = [];

				let possDatesArr = [];
				let possMiddleDates = [];

				eventArr.forEach(item => {
					if (item.start.dateTime) {
						let startDate = new Date(item.start.dateTime);
						datesArr.push(
							startDate.getDate() +
								'/' +
								startDate.getMonth() +
								'/' +
								startDate.getFullYear()
						);
					} else {
						let startDate = new Date(item.start.date);
						let endDate = new Date(item.end.date);

						for (
							let d = startDate;
							d < endDate;
							d.setDate(d.getDate() + 1)
						) {
							middleDates.push(
								d.getDate() +
									'/' +
									d.getMonth() +
									'/' +
									d.getFullYear()
							);
						}
					}
					let fullEventArr = datesArr.concat(middleDates);
					this.setState({ eventDates: fullEventArr });
				});

				possEventArr.forEach(item => {
					if (item.start.dateTime) {
						let startDate = new Date(item.start.dateTime);
						possDatesArr.push(
							startDate.getDate() +
								'/' +
								startDate.getMonth() +
								'/' +
								startDate.getFullYear()
						);
					} else {
						let startDate = new Date(item.start.date);
						let endDate = new Date(item.end.date);

						for (
							let d = startDate;
							d < endDate;
							d.setDate(d.getDate() + 1)
						) {
							possMiddleDates.push(
								d.getDate() +
									'/' +
									d.getMonth() +
									'/' +
									d.getFullYear()
							);
						}
					}
				});
				let possFullEventArr = possDatesArr.concat(possMiddleDates);
				this.setState({ possDates: possFullEventArr });
			})
		);
	}

	updateState(newDate) {
		let prevActiveMonth = this.state.currentMonth;
		let newLastMonth = ('0' + newDate['activeStartDate'].getMonth()).slice(
			-2
		);
		if (newLastMonth === '00') {
			newLastMonth = '01';
		}
		let newMonth = (
			'0' +
			(newDate['activeStartDate'].getMonth() + 1)
		).slice(-2);
		let newNextMonth = (
			'0' +
			(newDate['activeStartDate'].getMonth() + 2)
		).slice(-2);
		let newYear = newDate['activeStartDate'].getFullYear();
		let nextYear = newDate['activeStartDate'].getFullYear() + 1;

		if (newNextMonth === '13') {
			newNextMonth = '01';
			// newYear += 1;
		}

		let newNumDays = '';
		if (
			newMonth === '09' ||
			newMonth === '04' ||
			newMonth === '06' ||
			newMonth === '11'
		) {
			newNumDays = '30';
		} else if (newMonth === '02' && newYear % 4 === 0) {
			newNumDays = '29';
		} else if (newMonth === '02') {
			newNumDays = '28';
		} else {
			newNumDays = '31';
		}
		this.setState(
			{
				lastMonth: newLastMonth,
				currentMonth: newMonth,
				prevActiveMonth: prevActiveMonth,
				nextMonth: newNextMonth,
				numDays: newNumDays,
				currentYear: newYear,
				nextYear: nextYear
			},
			() => this.makeRequest()
		);
	}

	onChange(newDate) {
		this.updateState(newDate);
	}

	componentDidMount() {
		this.makeRequest();
	}
	render() {
		return (
			<div id='availability'>
				<div id='calendar-wrapper'>
					<h1 className='colTit'>
						<span class='cal'>Cal</span>endar
					</h1>
					<div id='calKey'>
						<small>
							<div id='free'> </div><p>Free</p>
						</small>
						<small>
							<div id='busy'> </div><p>Busy</p>
						</small>
						<small>
							<div id='unconfirmed'> </div><p>Unconfirmed</p>
						</small>
					</div>
					<div id='calendar'>
						<Calendar
							tileClassName={({ date }) => {
								let dateCheck =
									date.getDate() +
									'/' +
									date.getMonth() +
									'/' +
									date.getFullYear();

								if (this.state.eventDates.includes(dateCheck)) {
									return 'busy';
								} else if (
									this.state.possDates.includes(dateCheck)
								) {
									return 'poss';
								} else {
									return null;
								}
							}}
							minDetail='month'
							onActiveDateChange={this.onChange}
						/>
					</div>
				</div>
			</div>
		);
	}
}
