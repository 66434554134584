import React, { Component } from 'react';
import '../sass/Projects.scss';
import VideoPlayer from '../components/VideoPlayer';
// import Selector from './components/Selector';
import VidInfo from '../components/VideoInfo';
import {Helmet} from 'react-helmet';

export default class Projects extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: 'calTheMusical',
			info:
				'A comedy, juggling, meta-circus musical conveying the everyday struggles of a modern-day circus artist. Currently in its 2nd revision, Cal is performing the act whilst simultaneously improving on and extending it.',
			url:
				'356700221'
		};

		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(e) {
		if (e.target.name !== 'bookMe') {
			if (e.target.className !== 'active') {
				if (e.target.name === 'calTheMusical') {
					this.setState({
						title: 'calTheMusical',
						url: '356700221',
						info:
							'A comedy, juggling, meta-circus musical conveying the everyday struggles of a modern-day circus artist. Currently in its 2nd revision, Cal is performing the act whilst simultaneously improving on and extending it.'
					});
				} else if (e.target.name === 'chazAndCal') {
					this.setState({
						title: 'chazAndCal',
						url: '357054513',
						info: 'A juggling duo like no other...'
					});
				}
			}
		} else {
			const formName = document.getElementById('formName');
			formName.focus();
			formName.select();
		}
	}

	render() {
		return (
			<div id='projects'>
				<Helmet>
					<title>Cal Courtney Juggling - Projects</title>
					<meta name="description" content={"The projects page for juggling & circus entertainment from Cal Courtney! Click here for videos and information about Cal the Musical and more in " + new Date().getFullYear() + "-" + (new Date().getFullYear()+1)+"!"} />
				</Helmet>
				<VideoPlayer
					video={this.state.title}
					url={this.state.url}
				/>
				<VidInfo
					info={this.state.info}
					handleClick={this.handleClick}
				/>
				{/* <Selector
					active={this.state.title}
					handleClick={this.handleClick}
				/> */}
			</div>
		);
	}
}
