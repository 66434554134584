import React from 'react';
import {Helmet} from 'react-helmet';

import Contact from '../components/Contact';

import '../sass/Contact.scss';

export default function ContactPage() {
	return (
		<div id="contactContainer">
			<Helmet>
				<title>Cal Courtney Juggling - Contact</title>
				<meta name="description" content={"The contact page for Cal Courtney the only singing, juggling, one man musical in the world! Click here for bookings of Cal the Musical and more in " + new Date().getFullYear() + "-" + (new Date().getFullYear()+1)+"!"} />
			</Helmet>
			<Contact />
		</div>
	);
}
