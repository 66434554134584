import React, { Component } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Header from './components/Header/Header';
import Footer from './components/Footer';
import Preloader from './components/Preloader';
import HomePage from './pages/HomePage';
import ProjectsPage from './pages/ProjectsPage';
import CalleryPage from './pages/CalleryPage';
import ContactPage from './pages/ContactPage';
import RouteContainer from './routes/RouteContainer';

import './sass/App.scss';
import './sass/animations.scss';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const routes = [
			{ path: '/', name: 'Home', Component: HomePage },
			{ path: '/projects', name: 'Projects', Component: ProjectsPage },
			{ path: '/contact', name: 'Contact', Component: ContactPage },
			{ path: '/callery', name: 'Callery', Component: CalleryPage }
		];

		return (
			<Router>
				<Helmet>
					<title>Cal Courtney - Juggling & Circus</title>
					<meta name="description" content={"Juggling, circus and hosting entertainment from Cal Courtney! Click here for photos, videos & booking details for " + new Date().getFullYear() + "-" + (new Date().getFullYear()+1)+"."} />
				</Helmet>
				<div className="App">
					<Preloader />
					<div id="notFooter">
						<Header routes={routes} />
						<RouteContainer routes={routes} />
					</div>
					<Footer />
				</div>
			</Router>
		);
	}
}

export default App;
