import React, { Component } from 'react';
import facebook from '../assets/facebook.svg';
import instagram from '../assets/instagram.svg';
import twitter from '../assets/twitter.svg';

export default class Social extends Component {
	render() {
		return (
			<div id='social'>
				<div id='socContent'>
					<h1 className='colTit'>Social</h1>
					<p id='socialText'>
						Follow <span className="cal">@calthejuggler</span>
						<br />
						on Social Media!
					</p>
					<a href='http://www.facebook.com/CalTheJuggler'>
						<img
							src={facebook}
							alt='Facebook'
							className='socIcon'
						/>
					</a>
					<a href='http://www.instagram.com/calthejuggler'>
						<img
							src={instagram}
							alt='Instagram'
							className='socIcon'
						/>
					</a>
					<a href='http://twitter.com/calthejuggler/'>
						<img src={twitter} alt='Twitter' className='socIcon' />
					</a>
				</div>
			</div>
		);
	}
}
