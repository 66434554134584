import React from 'react';
import { Gallery, GalleryImage } from 'react-gesture-gallery';

// Image Imports
import gallery00 from '../assets/gallery/Panama-Drinks.jpg';
import gallery01 from '../assets/gallery/A-Gift-from-God-Death.jpg';
import gallery04 from '../assets/gallery/Hosting-Deliveroo-Bag.jpg';
import gallery07 from '../assets/gallery/Cal-the-Musical-backcrosses.jpg';
import gallery08 from '../assets/gallery/Panama-Wall-Juggling.jpg';
import gallery11 from '../assets/gallery/Tilburg-Pic.jpg';
import gallery15 from '../assets/gallery/Groupshow-Airoplane.jpg';
import gallery18 from '../assets/gallery/Cal-the-Musical-Checking-the-Phone.jpg';
import gallery20 from '../assets/gallery/Hosting-wide.jpg';
import gallery22 from '../assets/gallery/CatWalk-Gold-Shorts.jpg';
import gallery23 from '../assets/gallery/Panama-Sofa.jpg';

export default function Callery() {
	const [index, setIndex] = React.useState(0);
	const images = [
		{
			path: gallery00,
			alt:
				'Cal holding a tray of drinks before a performance with Panama Pictures.'
		},
		{
			path: gallery01,
			alt:
				'Cal, dead, after being given juggling. A way to express oneself that is so powerful it can be overcoming.'
		},
		{
			path: gallery04,
			alt:
				'Cal, inside of a large backpack, notifying the audience of the interval whilst passing a cigarette to his co-host who is carrying him.'
		},
		{ path: gallery07, alt: 'Cal performing three club backcrosses during Cal the Musical. A pretty difficult trick and a funny joke goes along with it. Unfortunately, it wouldn\'t work through a screen-reader' },
		{ path: gallery08, alt: 'Cal, Francesco and Fabian, juggling whilst sat on a wall during their show with Panama Pictures; A Perfect Deal' },
		{ path: gallery11, alt: 'Cal, lying on the ground and juggling three balls for a photo-shoot in the center of Tilburg, the Netherlands.' },
		{ path: gallery15, alt: 'ACAPA\'s fourth year of 2019 performing their groupshow. Cal is flying on the shoulder of his classmate and pretending to be an airplane.' },
		{ path: gallery18, alt: 'The most intense moment in Cal the Musical. Cal is standing in the center of the stage, looking down at his smartphone.' },
		{ path: gallery20, alt: 'A nice picture of Cal hosting a show.' },
		{ path: gallery22, alt: 'Cal in some incredibly small, shiny, gold shorts and an open-fronted, shiny, silver cardigan. A costume donned for his fourth year groupshow.' },
		{
			path: gallery23,

			alt: 'A promo shot from the premiere of Cal\'s show with Panama Pictures'
		}
	];

	React.useEffect(
		i => {
			const interval = setInterval(() => {
				if (index === images.length - 1) {
					setIndex(0);
				} else {
					setIndex(index + 1);
				}
			}, 2500);
			return () => clearInterval(interval);
		},
		[index]
	);
	return (
		<div id="callery">
			<Gallery
				index={index}
				onRequestChange={i => {
					setIndex(i);
				}}
			>
				{images.map(({path, alt}) => (
					<GalleryImage objectFit="contain" key={path} src={path} alt={alt} />
				))}
			</Gallery>
		</div>
	);
}
