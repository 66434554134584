import React from 'react';
import Callery from '../components/Callery'

import {Helmet} from 'react-helmet';

import '../sass/Callery.scss';



const CalleryPage = () => {
	

	return (
		<>
			<Helmet>
				<title>Cal Courtney Juggling - Gallery</title>
				<meta name="description" content={"The gallery page for juggling, circus & hosting entertainment from Cal Courtney! Click here for photos of Cal the Musical and more in " + new Date().getFullYear() + "-" + (new Date().getFullYear()+1)+"!"} />
			</Helmet>
			<Callery />
		</>
	);
};

export default CalleryPage;
