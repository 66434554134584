import React from 'react';
import {Link} from 'react-router-dom';
import vidInfoImage from '../assets/vidInfoImage.jpg';

export default function VidInfo(props) {
	return (
		<div id="vidInfo">
			<Link to="/contact">
				<button>
					Click here to book!
				</button>
			</Link>
			<p>{props.info}</p>
			<img src={vidInfoImage} alt="A shot from Cal the Musical. Cal is throwing away his props in frustration." id="vidInfoImage"/>
		</div>
	);
}
