import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from './components/NavBar';

import '../../sass/Header.scss';

export default function Header(props) {
	return (
		<div id="mainScreen">
			<Link to="/">
				<h1 id="mainTitle">
					<span className="cal">Cal</span>Courtney
					<small className="cal">.net</small>
				</h1>
			</Link>
			<NavBar routes={props.routes} />
		</div>
	);
}
