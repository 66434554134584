import React from 'react'

export default function VideoPlayer(props) {
	return (
		<>
				<div
					id='vid'
					style={{
						padding: '56.25% 0 0 0',
						position: 'relative'
					}}
				>
					<iframe
						src={'https://player.vimeo.com/video/'+props.url+'?color=ffffff&byline=0&portrait=0&title=0'}
						style={{
							position: 'absolute',
							top: '0',
							left: '0',
							width: '100%',
							height: '100%'
						}}
						frameBorder='0'
						title={props.video}
						allow='autoplay; fullscreen'
						allowFullScreen
					></iframe>
				</div>
				<script src='https://player.vimeo.com/api/player.js'></script>
			</>
	)
}
