import React, { Component } from 'react';
import '../sass/Footer.scss';

export default class Footer extends Component {
	render() {
		return (
			<footer id='footer'>
				<div id='mainFooterStuff'>
					<div id='footerRight'>
						<ul id='contDets'>
							<h3 className='footerTitle'>More Contact Info</h3>
							<li>
								<a href='mailto:info@calcourtney.net'>
									info@calcourtney.net
								</a>
							</li>
							<li>
								<a href='tel:+310615532184'>
									+31 (0) 6 155 321 84
								</a>
							</li>
							<li>
								<address>
									Hasseltstraat 137,
									<br />
									5046LJ, Tilburg,
									<br />
									The Netherlands
								</address>
							</li>
						</ul>
					</div>
					<div id='footerLeft'>
						<ul id='siteTools'>
							<h3 className='footerTitle'>Site Tools</h3>
							<a href='privacypolicy.htm' className='toolLink'>
								<li>Privacy Policy</li>
							</a>
							<a href='sitemap.xml' className='toolLink'>
								<li>Sitemap</li>
							</a>
							<a href='termsofservice.htm' className='toolLink'>
								<li>Terms of Service</li>
							</a>
						</ul>
					</div>
				</div>
				<div id='copyText'>
					&copy; CALCOURTNEY.NET ALL RIGHTS RESERVED
				</div>
				<div id='iconText'>
					Icons made by{' '}
					<a href='http://www.freepik.com/' title='Freepik'>
						Freepik
					</a>{' '}
					from{' '}
					<a href='https://www.flaticon.com/' title='Flaticon'>
						www.flaticon.com
					</a>{' '}
					is licensed by{' '}
					<a
						href='http://creativecommons.org/licenses/by/3.0/'
						title='Creative Commons BY 3.0'
						target='_blank'
						rel='noopener noreferrer'
					>
						CC 3.0 BY
					</a>
				</div>
			</footer>
		);
	}
}
