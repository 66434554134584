import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Availability from '../components/Availability';
import Social from '../components/Social';

import homeImage from '../assets/homeImage.jpg';

import '../sass/Home.scss';

export default class HomePage extends Component {
	render() {
		return (
			<div id="home">
				<Link id="homeJumbo" to="/projects">
					<img
						id="homeImage"
						src={homeImage}
						alt="A button to watch the video for Cal the Musical. It has a shot from the show with Cal juggling three balls."
					/>
				</Link>
				<div id="homeComponents">
					<Social />
					<Availability />
				</div>
			</div>
		);
	}
}
